import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from 'styled-components'
import { Div, H2, A } from '../styled-components'
import ButtonLink from '../buttonLink'
import { phone } from '../config'

const H1 = styled.h1`
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  @media (max-width: 960px) {
    font-size: 36px;
  }
`;

const Home = () => {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "home-banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 2048) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Div
      width="100%"
      height="650px"
      position="relative"
      height960="415px"
    >
      <Img
        fluid={data.banner.childImageSharp.fluid}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%"
        }}
      />
      <Div
        width="100%"
        height="100%"
        position="relative"
        display="flex"
        flexDirection="column"
        align_items="center"
        justify_content="center"
      >
        <H1>
          PREMIER ENERGY SOLUTIONS, INC
        </H1>
        <H2
          margin="30px 0"
          fontSize="22px"
          fontWeight="normal"
          textAlign="center"
          color="#fff"
          fontSize960="19px"
        >
          Associated Air Balance
        </H2>
        <Div
          width="530px"
          display="flex"
          justify_content="space-between"
          width960="250px"
          height960="100px"
          flexDirection960="column"
        >
          <ButtonLink
            to="/services"
            background="#666"
          >
            MORE DETAILS
          </ButtonLink>
          <A
            width="250px"
            height="45px"
            display="flex"
            align_items="center"
            justify_content="center"
            background="#69B02A"
            color="#fff"
            rel="noreferrer"
            href={`tel:${phone}`}
          >
            CALL
          </A>
        </Div>
      </Div>
    </Div>
  )
}

export default Home