import React from "react"
import PropTypes from "prop-types"
import { Div, P } from "../styled-components"
import BluePin from "../../assets/blue-pin.svg"
import GreenPin from "../../assets/green-pin.svg"

const PinContainer = ({ 
  bluePin,
  title,
  subTitle,
  footerTitle
}) => (
  <Div
    width="195px"
    height="210px"
    padding="10px"
    background_color="#f2f2f2"
    display="flex"
    flexDirection="column"
    align_items="center"
    justify_content="center"
  >
    {bluePin ? <BluePin alt="blue-pin" /> : <GreenPin alt="green-pin" />}
    <P
      margin="10px 0 0px"
      fontSize="20px"
      fontWeight="bold"
      color="#666"
      textAlign="center"
    >
      {title}
    </P>
    <P
      margin="0 0 10px"
      fontSize="20px"
      fontWeight="bold"
      color="#666"
      textAlign="center"
    >
      {subTitle}
    </P>
    <P
      fontSize="18px"
      color="#666"
      textAlign="center"
      margin="0"
    >
      {footerTitle}
    </P>
  </Div>
)

PinContainer.propTypes = {
  bluePin: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  footerTitle: PropTypes.string.isRequired,
}

export default PinContainer