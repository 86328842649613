import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Home from "../components/home/index"
import Band from "../components/band"
import Testing from "../components/home/testing"
import Certification from '../components/certification'
import Work from '../components/work'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Home />
    <Band
      bandBackground="#23227B"
    />
    <Testing />
    <Certification
      background="#f2f2f2"
    />
    <Work
      background="#23227B"
      title="EXPLORE RECENT WORK"
      colorTitle="#fff"
      spacebar
      subtitle
      button
    />
  </Layout>
)

export default IndexPage
