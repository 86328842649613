import React from 'react'
import { Div, P } from "../styled-components"
import PinContainer from './pinContainer'
import ButtonLink from '../buttonLink'

const Testing = () => (
  <Div
    width="100%"
    padding="80px 18px 30px"
    display="flex"
    flexDirection="column"
    align_items="center"
    justify_content="center"
  >
    <P
      maxWidth="513px"
      fontSize="30px"
      fontWeight="bold"
      color="#666"
      textAlign="center"
    >
      We are an Associated Air Balance Council Test and Balance Company
    </P>
    <Div
      width="280px"
      height="2px"
      background_color="#69B02A"
    />
    <P
      maxWidth="732px"
      margin="40px 0 5px"
      fontSize="18px"
      color="#666"
      textAlign="center"
    >
      We providing comprehensive services including hospitals, schools, laboratories, airports, restaurants and any type of commercial buildings. Whether you are installing a new system or are interested in improving the efficiency of an existing system, we will ensure that your equipment performs as intended and operates at optimum levels.
    </P>
    <Div
      margin="80px 0 40px"
      width="850px"
      display="flex"
      justify_content="space-between"
      width960="195px"
      height960="900px"
      flexDirection960="column"
    >
      <PinContainer
        bluePin={true}
        title="Test & Balance"
        subTitle="of Air & Hydronics"
        footerTitle="HVAC equipment and air and water distribution"
      />
      <PinContainer
        bluePin={false}
        title="Sound"
        subTitle="Testing"
        footerTitle="Calibrated meters are used to test to the project specifications"
      />
      <PinContainer
        bluePin={true}
        title="Smoke Control"
        subTitle="Testing"
        footerTitle="Complete testing and balancing of all smoke control systems"
      />
      <PinContainer
        bluePin={false}
        title="Duct Pressure"
        subTitle="Testing"
        footerTitle="Used to determine the amount of air any section of duct"
      />
    </Div>
    <ButtonLink
      to="/services"
      background="#666"
    >
      SEE ALL
    </ButtonLink>
  </Div>
)

export default Testing